/* src/styles.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 100%;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1 {
  text-align: center;
  color: #333;
}

input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.login-button {
  background-color: #007bff; /* Blue background for the login button */
}

.login-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.fixed-button {
  position: fixed;
  top: 20px;
  left: 20px;
  width: auto;
  padding: 10px 20px;
  background-color: #007bff; /* Blue background for the fixed button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.fixed-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.table-container {
  margin-top: 60px; /* Add margin to the top of the table */
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}

@media (max-width: 768px) {
  th, td {
    display: block;
    width: 100%;
  }

  th {
    background-color: transparent;
    text-align: right;
    padding-right: 50%;
    position: relative;
  }

  th::after {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    white-space: nowrap;
    font-weight: bold;
  }

  td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    white-space: nowrap;
    font-weight: bold;
  }

  th, td {
    padding: 15px;
  }
}
